const env = 'int' // env = env.replace('${env}', 'int')
const basePath = `https://api-${env}.teamwill-digital.com`
const processDefinitionId ='start-process'
const appVersion = '1.13.0.2-RELEASE'
export const settings = {
  api_url: `${basePath}`,
  api_query_url: `${basePath}/odm-dataservice/api/1/odm-dataservice/query/`,
  api_query_count_url: `${basePath}/odm-dataservice/api/1/odm-dataservice/queryCount/`,
  auth_anonymous_api_url: `${basePath}/odm-authentication/api/1/anonymous/token/?applicationName=TWDDemo`,
  auth_api_url: `${basePath}/odm-authentication/api/1/odm-authentication/token/?applicationName=TWDDemo`,
  env,
  processDefinitionId,
  appVersion
}
